@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.blog-slider-wrapper{
  width: 100%;
 padding: 80px 0 40px $mainPadding;
  .slick-slider{
    display: block!important;
    .slick-track{
      //width: 100%!important;
      display: flex!important;
      .slick-slide{
        padding-right: 30px;
        .news-block{
          &:hover{
            .news-block-info{
              .news-title-wrapper{
                .news-block-title{
                  color:$brandColor;
                }
                .news-block-link{
                  svg{
                    path{
                      stroke:$brandColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .slider-arr{
    margin-top: 60px;
    display: flex;
    align-items: center;
    .prev-btn, .next-btn{
      position: unset;
      transform: unset;
      transition: unset;
      border: 1px solid #EAECF0;
      box-shadow: unset;

    }
    .next-btn{
      margin-left: 32px;
    }
  }
}