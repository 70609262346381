@import "../mainVariables";
@import "../mixins";

.locations-wrapper {
  min-height: calc(100vh - 80px);
  width: 100%;
  position: relative;

  .map {
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
    border-radius: 4px;

    .marker {
      svg {
        width: 50px;
        height: 50px;
        transform: translate(-50%, -100%);
        path {
          fill: $brandColor;
        }
      }

    }
  }

}
