@import "../fonts";
@import "../mainVariables";

.footer-wrapper {
  background-color: white;
  color: #667085;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top-part {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
    width: 1120px;
    position: relative;
  }

  .bottom-part {
    width: 1120px;
    border-top: 1px solid #EAECF0;
    padding: 32px 0;
    display: flex;
    justify-content: space-between;

    .copyright {
      color: #667085;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .developed-by {
      display: flex;
      align-items: center;
      flex-direction: column;

      .develop {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;

        .footer-logo {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          margin-left: 10px;
          height: 40px;
        }
      }

      .design {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;

        p {
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: right;
          color: #667085;
          text-decoration: underline;
          margin-left: 6px;
        }
      }
    }
  }

  .col {
    color: #667085;

    .logo-wrapper {
      margin-bottom: 35px;
    }


    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #98A2B3;
      margin-bottom: 10px;
    }

    a {
      display: flex;
      align-items: center;
      color: #667085;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;


      &:hover, p:hover {
        color: black;
      }
    }


    p, a {
      color: #667085;
      font-size: 13px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text-wrapper {
    width: 350px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 10px;
  }

  .presentation-button {
    cursor: pointer;
    text-decoration: underline;
    position: absolute;
    bottom: 20px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;

    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-right: 16px;

      .social-icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }

      &:hover {
        color: black;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .top-part {
      width: 1020px
    }
    .bottom-part {
      width: 1020px;
    }
  }

  @media screen and (max-width: 1100px) {
    .top-part {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      padding: 60px $mainPadding;

      .col {
        margin-top: 20px;

        .text-wrapper {
          width: 100%;
        }
      }
    }

    .bottom-part {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .copyright {
        text-align: center;
      }
    }
  }

}
