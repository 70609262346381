@import "../mainVariables";
@import "../mixins";

.services{
  width: 1120px;
  //padding: 0 $mainPadding 0;
  position: relative;
  background-color: white;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 90px;
    .text{
      width: 150px;
      padding: 4px 0;
      color:$brandColor;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      background-color: #EDF3FF;
      border-radius: 16px;
      margin-bottom: 16px;
    }
    .title{
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-align: center;
      margin-bottom: 20px;
    }
    .description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #667085;
    }
  }
  .services-wrapper{
    margin-top: 40px;
    padding-bottom: 90px;
    .service-block{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
     padding-top: 60px;
      .text-block{
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        .bg-block{
          position: absolute;
          top: 42%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
        .responsiveBg-block{
          display: none;
        }
        .blocks-title{
          font-size: 36px;
          font-weight: 600;
          line-height: 52px;
          letter-spacing: -0.02em;
          text-align: left;
          margin: 32px 0 20px 0;
          z-index: 10;
        }
        .blocks-description{
          font-size: 16px!important;
          font-weight: 400!important;
          line-height: 24px!important;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          margin-bottom: 32px;
          z-index: 10;
          p{
            span{
              font-size: 16px!important;
              font-weight: 400!important;
              line-height: 24px!important;
              font-family: unset;
            }
          }
        }
        .more-info{
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color:$brandColor;
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 10;
          svg{
            margin-left: 12px;
          }
        }
      }
      .img-block{
        width: 48%;
        display: contents;
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 1020px;
  }

  @media screen and (max-width: 1100px){
    width: 100%;
    border-bottom: 1px solid #DCE0E8;
    .header{
      width: 90%;
      border-bottom: 1px solid #DCE0E8;
      .title{
        font-size: 26px;
      }
      .description{
        margin-bottom: 60px;
        font-size: 18px;
      }
    }
    .services-wrapper{
      padding: 0 $mainPadding 90px $mainPadding;
      .service-block{
        flex-direction: column-reverse;
        .text-block{
          width: 100%;
          .bg-block{
            display: none;
          }
          .responsiveBg-block{
            display: flex;
            position: absolute;
            right: 0;
            bottom: -220px;

          }
          .blocks-title{
            font-size: 26px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: 0;
            text-align: left;
          }
        }
        .img-block{
          width: 100%;
          svg{
            width: 100%;
            height: 100%;
            z-index: 100;
          }
        }
        &:nth-child(2){
          flex-direction: column;
        }
        &:nth-child(3){
            .text-block{
              .responsiveBg-block{
                bottom: -94px;
              }
            }
        }
      }
    }
  }
}
