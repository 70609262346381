@import "../mainVariables";
@import "../mixins";

.blog-wrapper {
  width: 1120px;
  .subscribe-wrapper{
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .subtitle{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color:$brandColor;
    }
    .title{
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.02em;
      text-align: center;
      margin-top: 12px;
    }
    .description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      margin-top: 24px;
    }
    .input-wrapper{
      width: 52%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 24px;
      .sent-email{
        padding: 12px 20px;
        background-color:$brandColor;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-left: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
      }
      .custom-input-group{
        width: 70%;
        margin-bottom: unset;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        input{
          padding: 12px 14px;
          height: 48px;
          border-radius: 8px;
          background-color: white;
          border-bottom: unset;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }
  }

  .last-news-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    .last-news-title{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
      margin: 30px 0;
    }
    .last-news{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .first-news-block{
        width: 48%;
        height: 100%;
        .first-news{
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          .last-news-img{
            padding-top: 60.6%;
            position: relative;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .last-news-info{
            margin-top: 32px;
            .date{
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color:$brandColor;
            }
            .title-wrapper{
              width: 100%;
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              margin-top: 12px;
              .title{
                width: 90%;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .share{
                cursor: pointer;
              }
            }
            .description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
              color:#667085;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        :hover{
          .title-wrapper{
            .title{
              color:$brandColor;
            }
            .share{
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
      }
      .other-news-block{
        width: 48%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .other-news{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .img-wrapper{
            width: 52%;
            .last-news-img{
              padding-top: 79.5%;
              position: relative;
              img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .last-news-info{
            width: 44%;
            display: flex;
            flex-direction: column;
            .date{
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color:$brandColor;
            }
            .title-wrapper{
              width: 100%;
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              margin-top: 12px;
              .title{
                width: 96%;
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .share{
                cursor: pointer;
              }
            }
            .description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
              color: #667085;
              margin-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          &:last-child{
            margin-top: 36px;
          }
        }
        :hover{
          .title-wrapper{
            .title{
              color:$brandColor;
            }
            .share{
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
      }
    }
  }

  .news-wrapper{
    margin-top: 90px;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 60px;
    margin-bottom: 20px;
    .news-wrapper-title{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
    }
    .news-block{
      &:hover{
        .news-block-info{
          .news-title-wrapper{
            .news-block-title{
              color:$brandColor;
            }
            .news-block-link{
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.hide-prev{
      .ant-pagination-prev{
        display: none;
      }
    }
    &.hide-prev{
      .ant-pagination-next{
        display: none;
      }
    }
    .prev_btn{
      height: 40px;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
      svg{
        margin-right: 12px;
      }
    }
    .next_btn{
      height: 40px;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
      svg{
        margin-left: 12px;
      }
    }
    .ant-pagination-item{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      a{
        color: #667085;
      }
    }
    .ant-pagination-item-active{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F9FAFB;
      border-radius: 8px;
      a{
        color: black;
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 1020px;
  }
  @media screen and (max-width: 1100px){
    width: 100%;
    padding: 0 $mainPadding;
    .subscribe-wrapper{
      padding: unset;
      margin-top: 60px;
      .title{
        font-size: 32px;
      }
      .description{
        font-size: 14px;
      }
      .input-wrapper{
        width: 100%;
        flex-direction: column;
        .custom-input-group{
          width: 100%;
        }
        .sent-email{
          width: 100%;
          margin-left: unset;
          margin-top: 16px;
        }
      }
    }
    .last-news-wrapper{
      .last-news-title{
        font-size: 20px;
      }
      .last-news{
        flex-direction: column;
        .first-news-block{
          width: 100%;
          .first-news{
            .last-news-img{
              padding-top: 58.3%;
            }
            .last-news-info{
              .date{
                font-size: 14px;
              }
              .title-wrapper{
                .title{
                  font-size: 20px;
                }
              }
              .description{
                font-size: 16px;
                -webkit-line-clamp: 3;
              }
            }
          }
        }
        .other-news-block{
          margin-top: 32px;
          width: 100%;
          .other-news{
            flex-direction: column;
            align-items: unset;
            .img-wrapper{
              width: 100%;
              .last-news-img{
                padding-top:58.3%;
              }
            }
            .last-news-info{
              width: 100%;
              .date{
                margin-top: 32px;
                font-size: 14px;
              }
              .title-wrapper{
                .title{
                  font-size: 20px;
                }
              }
              .description{
                font-size: 16px;
                -webkit-line-clamp: 3;
              }
            }
          }
        }
      }
    }
    .news-wrapper{
      .news-wrapper-title{
        font-size: 20px;
      }
    }
    .pagination{
      .prev_btn{
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-right: unset;
        }
        span{
          display: none;
        }
      }
      .next_btn{
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-left: unset;
        }
        span{
          display: none;
        }
      }
      .ant-pagination-simple-pager{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
        margin-right: unset;
        input{
          margin-right: unset;
          padding: unset;
          border: unset;
        }
      }
    }
  }
}