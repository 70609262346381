@import "../mainVariables";
@import "../mixins";

.contacts-page-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .contacts-block{
    padding: 0 0 80px $mainPadding ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .contacts-main-block{
      width: 520px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .contacts-main-text{
        width: 100%;
        margin-bottom: 24px;
        .main-title{
          font-size: 48px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .main-description{
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          margin-top: 24px;
        }
      }
      .contacts-inputs-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .fullName-inputs{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .custom-input-group{
            width: 48%;
            input{
              height: 44px;
              border-bottom: unset;
              border: 1px solid #D0D5DD;
              border-radius: 8px;
              padding: 14px 12px;
              margin-top: 6px;
            }
          }
        }
        .inputs{
          .custom-input-group{
            width: 100%;
            margin-bottom: 24px;
            input{
              height: 44px;
              border-bottom: unset;
              border: 1px solid #D0D5DD;
              border-radius: 8px;
              padding: 14px 12px;
              margin-top: 6px;
            }
            textarea{
              height: 120px;
              border-bottom: unset;
              border: 1px solid #D0D5DD;
              border-radius: 8px;
              padding: 10px 14px;
              margin-top: 6px;
            }
          }
          span{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: #344054;
            margin-bottom: 6px;
          }
          .phones-wrapper{
            border: 1px solid #D0D5DD;
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 8px;
            margin-bottom: 24px;
            margin-top: 6px;
            .custom-input-group{
              width: unset;
              margin-bottom: unset;
              .ant-select{
                width: 70px!important;
                margin-top: 6px;
                .ant-select-selector{
                  border: unset;
                  display: flex;
                  align-items: center;
                  .ant-select-selection-item{
                    margin-bottom: 2px!important;
                  }
                }
              }
              input{
                width: 94%;
                height: 44px;
                border: unset;
                padding: unset;
                margin-bottom: 2px;
              }
            }
          }
          .invalidPhone{
            border: 1px solid red;
          }
        }
      }
      .contact-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        .contact-services-title{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #344054;
          margin-bottom: 16px;
        }
        .services-block{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ant-checkbox-group{
            width: 100%;
            .ant-row{
              width: 100%;
              .services-left-block, .services-right-block{
                width: 48%;
                display: flex;
                flex-direction: column;
                .ant-checkbox-wrapper{
                  margin-bottom: 10px;
                  .ant-checkbox{
                    .ant-checkbox-inner{
                      width: 20px;
                      height: 20px;
                      border: 1px solid #D0D5DD;
                    }
                  }
                  span{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-align: left;
                    color: #344054;
                  }
                }
              }
            }
          }
        }
      }
      .sent-email{
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        background-color:$brandColor;
        color: white;
        border-radius: 8px;
        margin-top: 32px;
      }
    }
    .contact-map-block{
      width: 50%;
      height: 1000px;
      .yandexMap{
        width: 100%;
        height: 100%;
        .ymaps-2-1-79-map{
          width: 100%!important;
          height: 100%!important;

        }
      }
    }
  }
  .information-block{
    width: 1120px;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 160px;
    .contact-info{
      width:calc(96% / 3);
      height: 290px;
      background-color: #F9FAFB;
      padding: 24px;
      border-radius: 12px;
      .info-icon{
        width: 48px;
        height: 48px;
        background-color: #5080E5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .info-title{
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 64px;
      }
      .info-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        margin-top: 8px;
        margin-bottom: 20px;
      }
      .wrapper{
        height: 60px;
        .information{
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color:$brandColor;
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    .contacts-block{
      flex-direction: column;
      padding: 60px $mainPadding;
      .contacts-main-block{
        width: 100%;
        .contacts-main-text{
          .main-title{
            font-size: 30px;
            line-height: 40px;
          }
          .main-description{
            font-size: 14px;
            line-height:20px;
          }
        }
        .contacts-inputs-wrapper{
          .fullName-inputs{
            flex-direction: column;
            .custom-input-group{
              width: 100%;
            }
          }
        }
        .contact-services{
          .services-block{
            flex-direction: column;
            align-items: start;
            .ant-checkbox-group{
              .ant-row{
                display: flex;
                flex-direction: column;
                .services-left-block{
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .contact-map-block{
        display: none;
      }
    }
    .information-block{
      width: 100%;
      padding: 60px $mainPadding;
      flex-direction: column;
      margin-bottom: 260px;
      .contact-info{
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}
