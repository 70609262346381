@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";
@import "./assets/styles/mixins";
@import "assets/styles/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}

body {
  margin: 0;
  font-family: NotoArmenia,Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //@extend .custom-scrollbar-y;
  height: 100%;
  width: 100% ;
  > iframe{
    display: none;
  }
}

  button {
    cursor: pointer;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  p {
    margin-bottom: 0;
  }

  a, a:hover {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: unset!important;
    //padding-left: 0;
    margin-bottom: 0;
  }

  input, select {
    border: none;
    &:focus {
      outline: none;
    }
  }

.sun-editor-editable{
  ul {
    //list-style: unset;
    //padding-left: unset;
    margin-bottom: unset;
  }
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $inputBGColor !important;
  -webkit-box-shadow: 0 0 0 30px $inputBGColor inset !important;

}

.ant-scrolling-effect{
  //width: 100% !important;
}
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: auto;
  transform: translate(-50%, -50%);
  z-index: 9900;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9900;
  bottom: 0;
  background: rgba(0, 0, 0, .35);
}

.separator {
  border-top: 1px solid $borderColor;
}
.languages-dropdown {
  border-radius: 3px;
  padding: 0;
  .ant-dropdown-menu-item {
    position: relative;
    left: -10px;
    padding: 6px;
    &:hover{
      background-color: transparent!important;
    }
    span{
      margin-left: 10px;
    }
    img{
      width: 24px;
      height: 16px;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}
.ant-picker-footer{
  display: none !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner{
  display: none !important;
}
.popup-input-time{
  &.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel{
    overflow: hidden;
    height: 284px;
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .ant-picker-time-panel-column::after{
    display: none!important;
  }
}
.ant-modal-wrap{
  z-index: 1020;
}
 .error{
    input{
      border-bottom: 1.2px solid red !important;
    }
}
.close{
  display: none !important;
}
.languages-dropdown{
  width: 53px!important;
  position: relative;
  margin-left: 30px;
}
.selected-language{
  display: flex;
  align-items: center;
  img{
    width: 26px;
    margin: 4px 5px 0 0;
  }
  svg{
    margin:10px 0 0 5px;
    g{
      path{
        fill: black;
      }
    }
  }
}
.ant-dropdown-placement-bottom {
  position: relative;
  max-width: 110px!important;
  bottom: 0;
  top: calc(100% + 60px);
}
.right-menu-wrapper .right-menu-content .ant-dropdown-trigger{
  margin: 0 !important;
}
.ant-select-arrow{
  svg{
    polygon{
      fill: #949BA4 !important;
    }
  }
}

.ant-dropdown-placement-bottomLeft{
  animation-duration: unset!important;
  top: 74px!important;
}

[class*="ground-pane"] {
  filter: grayscale(1) brightness(0.5)
}
