@import "../mainVariables";
@import "../mixins";

.consultation-wrapper{
  width: 100%;
  background-color: white;
  position: relative;
  padding-bottom: 180px;
  .background-wrapper{
    width: 100%;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .consultation{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    margin: 20px 0;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

    .responsive-wrapper{
      display: none;
    }
    .mobile-block{
      display: none;
    }

    .consultation-title{
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-align: center;
      margin-bottom: 20px;
    }

    .consultation-description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color:#667085;
      margin-bottom: 20px;
    }
    .consultation-link{
      width: 220px;
      cursor: pointer;
      background-color:$brandColor;
      color: white;
      border-radius: 8px;
      padding: 16px 28px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1100px){
    padding-bottom: 340px;
    overflow: hidden;
    .background-wrapper{
      display: none;
    }
    .consultation{
      position: unset;
      transform: unset;
      .consultation-title{
        font-size: 30px;
        margin-top: 140px;
      }
      .consultation-description{
        font-size: 14px;
      }
      .mobile-block{
        //width: 100%;
        //margin-top: 40px;
        display: flex;
      }
    }
  }

  @media screen and (max-width:370px){
    align-items: center;
    justify-content: center;
    .background-wrapper{
      display: none;
    }
    .consultation{
      position: unset;
      transform: unset;
      padding-top: 48px;
      .responsive-wrapper{
        width: 100%;
        margin-top: 40px;
        display: flex;
      }
      .mobile-block{
        display: none;
      }
    }
  }
}