@import "../mainVariables";
@import "../mixins";

.color{
  background-color: #F9FAFB;
}
.fixe{
  position: fixed!important;
  width: 100%;
  background-color: #F9FAFB;
}
.header-wrapper {
  //padding: 0 $mainPadding;
  position: relative;
  top: 0;
  z-index: 1002;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-block{
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bg-block{
      width: 52%;
      position: absolute;
      right: 0;
      background-color: white;
      border-top-left-radius: 100%;
      overflow: hidden;
      height: 235px;
    }

    a{
      .ask-logo{
        svg{
          width: 150px;
          height: 100px;
        }
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      padding-right: 44px;
      z-index: 1000;
      list-style: none!important;
      .active{
        .nav-item{
          color:$brandColor;
        }
      }

      .nav-item {
        margin-left: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        cursor: pointer;

        &:hover {
          color: $brandColor;
        }

        .selected-language{
          background: #EFF2F4;
          padding: 0 12px;
          border-radius: 4px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          .language_icon{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              margin: unset;
            }
          }
        }
      }

      .header_burger {
        display: none;
        position: absolute;
        top: 46%;
        transform: translateY(-50%);
        right: 23px;
        width: 28px;
        height: 28px;
        cursor: pointer;
        svg{
          width: 100%;
          height: 100%;
        }
      }
    }
    div{
      position: relative !important;
    }

    @media all and (max-width: 1300px) {
      width: 1020px;
      .nav-menu {

        .nav-item {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 1100px){
      border-bottom: 1px solid #EFF2F4;
      width: 100%;
      .bg-block{
        display: none!important;
      }
      .header_burger{
        display: flex!important;
      }
      .nav-menu {

        .nav-item {
          display: none;
        }
      }
    }
  }
}