
:root {
  --brandColor: #0F47C0;
  --borderRadius: 8px;
  --mainPadding: 120px;
  --asideContentWidth: 500px;
}

$asideContentWidth: var(--asideContentWidth);
$mainPadding: var(--mainPadding);
$borderRadius: var(--borderRadius);

//--------Colors---------
$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$borderColor: #E8E8E8;
$textColor: #333333;
$greyTextColor: #949BA4;
$mainBgColor: #FDFDFF;

$inputBorderColor: #868686;
$inputBGColor: white;
$inputTextColor: #1D1C1D;

$pinkColor: #FE1863;
$greyColor: #959595;
$greyBgColor: #F3F4F6;
$greyBorderColor: #D5D7E3;
$darkTextColor: #252629;
$lightGreyColor: #92969B;
$greenColor: #16C67B;
$darkGreenColor: #018C44;
$yellowColor: #FFCE00;
$redColor: #F54641;


@media (max-width: 1700px) {
  :root {
    --asideContentWidth: 430px;
  }
}

@media (max-width: 1500px) {
  :root {
    --asideContentWidth: 380px;
    --mainPadding: 110px;
  }
}

@media (max-width: 1400px) {
  :root {
    --mainPadding: 100px;
  }
}

@media (max-width: 1300px) {
  :root {
    --mainPadding: 40px;
    --asideContentWidth: 300px;
  }
}

@media (max-width: 1100px) {
  :root {
    --mainPadding: 30px;
  }
}


@media (max-width: 800px) {
  :root {
    --asideContentWidth: 250px;
    --mainPadding: 20px;
  }
}

@media (max-width: 600px) {
  :root {
    --mainPadding: 15px;
  }
}

@media (max-width: 500px) {
  :root {
    --mainPadding: 10px;
  }
}
