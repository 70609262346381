@import "../mainVariables";
@import "../mixins";

.services-page-wrapper {
  //-webkit-user-select: none !important;
  //user-select: none !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .services-main-block {
    background-color: #F9FAFB;
    width: 100%;
    padding: 80px $mainPadding 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .services-main{
      width: 1120px;
      display: flex;
      align-items: center;
      .main-info {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 1000;

        .responsiveBg-block {
          display: none;
        }

        .main-title {
          font-size: 50px;
          font-weight: 600;
          line-height: 68px;
          letter-spacing: -0.02em;
          text-align: left;
        }

        .main-description {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          margin: 24px 0 48px 0;
        }

        .main-consultation {
          width: 40%;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: white;
          background-color: $brandColor;
          border-radius: 12px;
          padding: 16px 28px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .bg-block {
        width: 60%;
        position: absolute;
        right: 0;
        background-color: white;
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        overflow: hidden;
        height: 130%;
      }

      .img-block {
        z-index: 1000;
        width: 44%;
        position: relative;
        right: -80px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .service-page-block {
    width: 1120px;

    .services-features-block {
      width: 100%;
      padding: 60px 0 60px;
      z-index: 1001;
      position: relative;

      .services-features-title {
        width: 60%;
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
      }

      .services-features-text {
        width: 70%;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        margin-top: 20px;
      }
    }

    .features-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;

      .feature {
        width: calc(95% / 3);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 30px;

        .feature-title {
          width: 70%;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 20px;
        }

        .feature-description {
          font-size: 16px!important;
          font-weight: 400!important;
          line-height: 24px!important;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          margin-top: 8px;
          p{
            span{
              font-size: 16px!important;
              font-weight: 400!important;
              line-height: 24px!important;
              font-family: unset!important;
            }
          }
        }
      }
    }

    .blocks-wrapper {
      display: flex;
      padding: 0 0 80px;
      flex-direction: column;
      align-items: center;

      .blocks {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 160px;

        .blocks-img {
          width: 40%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .blocks-text {
          width: 48%;
          position: relative;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          .responsive-bg-icon {
            display: none;
          }

          .bg-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
          }

          .blocks-title {
            width: 80%;
            z-index: 1000;
            font-size: 36px;
            font-weight: 600;
            line-height: 44px;
            letter-spacing: -0.02em;
            text-align: left;
          }

          .blocks-description {
            width: 80%;
            font-size: 16px!important;
            font-weight: 400!important;
            line-height: 24px!important;
            letter-spacing: 0;
            text-align: left;
            color: #667085;
            margin-top: 24px;
            z-index: 100;
            p{
              span{
                font-size: 16px!important;
                font-weight: 400!important;
                line-height: 24px!important;
                font-family: unset!important;
              }
            }
          }
        }

        &:nth-child(odd) {
          flex-direction: row-reverse;
        }
      }
    }

  }
  @media screen and (max-width: 1250px) {
    .services-main-block{
      .services-main{
        width: 1020px;
      }
    }
    .service-page-block{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px) {
    .services-main-block {
      flex-direction: column;
      padding: unset;

      .services-main{
        width: 100%;
        flex-direction: column;
        .main-info {
          width: 100%;
          padding: 60px 20px;
          position: relative;

          .main-title {
            font-size: 34px;
            font-weight: 600;
            line-height: 46px;
            letter-spacing: -0.02em;
            text-align: left;
            z-index: 100;
          }

          .main-description {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            z-index: 100;
          }

          .main-consultation {
            width: 100%;
            font-size: 16px;
            z-index: 100;
          }

          .responsiveBg-block {
            display: flex;
            position: absolute;
            right: 0;
            bottom: 0;

          }
        }

        .bg-block {
          display: none;
        }

        .img-block {
          width: 100%;
          background-color: white;
          padding: 40px;
          right: unset;
        }
      }
    }
    .service-page-block{
      width: 100%;
      padding: 0 $mainPadding;
      .services-features-block {
        .services-features-title {
          width: 100%;
          font-size: 30px;
        }

        .services-features-text {
          width: 100%;
          font-size: 18px;
        }
      }
      .features-wrapper {
        flex-wrap: unset;
        flex-direction: column;

        .feature {
          width: 100%;

          .feature-title {
            width: 100%;
            font-size: 18px;
          }

          .feature-description {
            width: 100%;
            font-size: 16px;
          }
        }
      }
      .blocks-wrapper {
        margin: 60px 0;

        .blocks {
          flex-direction: column-reverse;

          .blocks-img {
            width: 100%;
          }

          .blocks-text {
            width: 100%;
            margin-bottom: 60px;

            .bg-icon {
              display: none;
              transform: unset;
            }

            .responsive-bg-icon {
              display: flex;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%)
            }

            .blocks-title {
              width: 100%;
              font-size: 24px;
            }

            .blocks-description {
              width: 100%;
              font-size: 16px;
            }
          }

          &:nth-child(odd) {
            flex-direction: column-reverse;
            margin-bottom: 60px;
          }
        }
      }
    }
  }
}