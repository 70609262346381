@import "../mainVariables";
@import "../mixins";

.about{
  width: 1120px;
  padding-bottom: 120px;
  position: relative;
  background-color: white;
  .header-block{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 90px;
    .title{
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-align: center;
    }
    .description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #667085;
      margin-top: 20px;
    }
  }
  .about-info{
    width: 100%;
    background-color: #1D2939;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 68px;
    margin: 64px 0 0 0;
    .info-block{
      width: calc(100% / 5);
      display: flex;
      align-items: center;
      flex-direction: column;
      .parametric{
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: center;
        color: white;
      }
      .parametric-text{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: #EAECF0;
        margin-top: 8px;
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 1020px;
  }
  @media screen and (max-width: 1100px){
    width: 100%;
    padding: 40px 0;
    .header-block{
      width: 100%;
      padding: 0 $mainPadding 0;
      .title{
        font-size: 26px;
      }
      .description{
        font-size: 18px;
      }
    }
    .about-info{
      flex-direction: column;
      border-radius: unset;
      .info-block{
        width: 100%;
        margin: 30px 0;
      }
    }
  }
}