@import "../mainVariables";
@import "../mixins";

.team-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .team-block{
    width: 1120px;
    .team-main-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 80px 0 80px;
      .team-main{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .team-main-subtitle{
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          color:$brandColor;
        }
        .team-main-title{
          font-size: 48px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 12px;
        }
        .team-main-description{
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: center;
          color: #667085;
          margin-top: 24px;
        }
      }
    }
    .members-wrapper{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid #EAECF0;
      padding: 60px 0;
    }
    .teams-feathers-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 0 80px;
      margin-bottom: 80px;
      .teams-feathers-info{
        width: 74%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .feathers-subTitle{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          color:$brandColor;
        }
        .feathers-title{
          font-size: 48px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 12px;
        }
        .feathers-description{
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: center;
          color: #667085;
          margin-top: 24px;
        }
      }
      .feathers-block{
        width: 96%;
        padding: 60px 0;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .feather{
          width: calc(94% / 3);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .feather-icon{
            margin-bottom: 20px;
          }
          .feather-title{
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0;
            text-align: center;
          }
          .feather-description{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color: #667085;
            margin-top: 8px;
          }
        }
      }
    }

    @media screen and (max-width: 1250px){
      width: 1020px;
    }
    @media screen and (max-width: 1100px){
      width: 100%;
      padding: 0 $mainPadding;
      .team-main-wrapper{
        .team-main{
          width: 100%;
          .team-main-title{
            font-size: 34px;
          }
          .team-main-description{
            font-size: 16px;
          }
        }
      }
      .members-wrapper{
        flex-direction: column;
        flex-wrap: unset;
      }
      .teams-feathers-wrapper{
        margin-bottom: 160px;
        .teams-feathers-info{
          width: 100%;
          .feathers-title{
            font-size: 34px;
          }
          .feathers-description{
            font-size: 16px;
          }
        }
        .feathers-block{
          flex-direction: column;
          align-items: center;
          .feather{
            width: 90%;
            margin-top: 40px;
          }
        }
      }
    }
  }
}