@import "../mainVariables";
@import "../mixins";

.about-wrapper{
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .about-main-wrapper{
    width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0 80px;
    .about-main{
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .about-main-subtitle{
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color:$brandColor;
      }
      .about-main-title{
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.02em;
      text-align: center;
        margin-top: 12px;
        @media screen and (max-width: 1350px){
          font-size: 44px;
        }
    }
      .about-main-description{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: center;
        color: #667085;
        margin-top: 24px;
      }
    }
  }
  .blocks-wrapper{
    width: 1120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blocks{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 80px;
      .blocks-img{
        width: 50%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .blocks-text{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .blocks-description{
          width: 76%;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          z-index: 100;
          p{
            span{
              font-family: inherit!important;
            }
          }
          ul{
            margin-left: 50px!important;
          }
        }
      }
      &:nth-child(odd){
        flex-direction: row-reverse;
      }
    }
  }
  .additional-info-block{
    width: 100%;
    background-color: #F9FAFB;
    display: flex;
    align-items: center;
    justify-content: center;
    .additional-info-wrapper{
      width: 1120px;
      padding: 80px 0 80px;
      margin-bottom: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .additional-info-img{
        width: 46%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .additional-info{
        width: 50%;
        display: flex;
        flex-direction: column;
        .additional-info-title{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color:$brandColor;
        }
        .additional-info-description{
          font-size: 46px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: left;
          margin-top: 12px;
        }
        .info-block{
          width: 84%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .some-info{
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            .parametric{
              font-size: 60px;
              font-weight: 600;
              line-height: 72px;
              letter-spacing: -0.02em;
              text-align: left;
              color:$brandColor;
            }
            .parametric-text{
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0;
              text-align: left;
              margin-top: 12px;
            }
          }
          .left-info-block{
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
          .right-info-block{
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .about-main-wrapper{
      width: 1020px;
    }
    .blocks-wrapper{
      width: 1020px;
    }
    .additional-info-block{
      .additional-info-wrapper{
        width: 1020px;
      }
    }
  }
  @media screen and (max-width: 1100px){
    .about-main-wrapper{
      width: 100%;
      padding: 80px $mainPadding;
      .about-main{
        width: 100%;
        .about-main-subtitle{
          font-size: 16px;
        }
        .about-main-title{
          font-size: 30px;
          overflow: hidden;
        }
        .about-main-description{
          font-size: 16px;
          overflow: hidden;
        }
      }
    }
    .blocks-wrapper{
      width: 100%;
      padding: 40px $mainPadding 80px;
      .blocks{
        flex-direction: column-reverse;
        .blocks-img{
          width: 100%;
        }
        .blocks-text{
          width: 100%;
          margin-bottom: 40px;
          .blocks-description{
            width: 100%;
            font-size: 16px;
          }
        }
        &:nth-child(odd){
          flex-direction: column-reverse;
        }
      }
    }
    .additional-info-block{
      .additional-info-wrapper{
        width: 100%;
        padding: 80px $mainPadding;
        flex-direction: column-reverse;
        .additional-info-img{
          width: 100%;
          margin-top: 48px;
        }
        .additional-info{
          width: 100%;
          .additional-info-title{
            font-size: 14px;
            overflow: hidden;
          }
          .additional-info-description{
            font-size: 30px;
            overflow: hidden;
          }
          .info-block{
            flex-direction: column;
            .some-info{
              .parametric{
                font-size: 48px;
              }
              .parametric-text{
                font-size: 18px;
              }
            }
            .left-info-block{
              width: 100%;
            }
            .right-info-block{
              width: 100%;
            }
          }
        }
      }
    }
  }
}