@import "../mainVariables";
@import "../mixins";

.description-wrapper{
  width: 100%;
  padding: 0 0 0  $mainPadding ;
  position: relative;
  background-color: #F9FAFB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .description-content{
    width: 100%;
    padding-top: 90px;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: space-between;
    .text-content{
      width: 60%;
      p{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color:$brandColor;
        margin-bottom: 12px;
      }
      .title{
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 20px;
      }
      .text{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
    }
    .bg-wrapper{
      width: 40%;
      position: absolute;
      display: flex;
      justify-content: flex-end;
        right: 0;
        bottom: -240px;
    }
  }
  .projects-block{
    width:100%;
    margin-top: 60px;
    padding-bottom: 80px;
    .project{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .project-title{
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        margin: 16px 0;
        color: black;
      }
      .project-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        word-break: break-all;
        margin-bottom: 20px;
      }
      .project-more-news{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color:$brandColor;
        cursor: pointer;
        svg{
          margin-left: 10px;
        }
      }
    }
    .img-wrapper{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      right: -24px;

      .img{
        width: 100%;
        overflow: hidden;
        z-index: 999;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .description-content{
      width: 1020px;
    }
    .projects-block{
      width: 1020px;
    }
  }
  @media screen and (max-width:1100px){
    z-index: -1;
    .description-content{
      width: 100%;
      .text-content{
        width: 100%;
      }
      .bg-wrapper{
        display: none;
      }
    }
    .projects-block{
      width: 100%;
      .img-wrapper{
        display: none;
      }
    }
  }
}