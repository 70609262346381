@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 100%;
  padding-bottom: 60px;
  border-top: 1px solid #DCE0E8;
  background-color: #F9FAFB;
  display: flex;
  align-items: center;
  justify-content: center;

  .partners-block{
    width: 1120px;

    .partners-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0;
      color: #667085;
      margin: 48px 0;
      text-align: center;
    }

    .partners-list-wrapper {
      position: relative;

      .slick-slider {
        .slick-list {
          .slick-track {
            margin-left: auto;
            padding-top: 5px;
            @media screen and (max-width: 400px){
              margin-right: 90px;
              width: 360px!important;
            }
          }
        }
      }

      .partner-item-wrapper{
        .partner-item {
          //width: 90%;
          //height: 100%;
          //position: relative;
          width: 100%;
          height: 100%;
          margin-right: 20px;

          .partner-content {
            width: 100%;
            height: 100%;
            padding-top: 28%;
            position: relative;
            img {
              //width: 100%;
              height: 100%;
              //height: 100%;
              //margin: auto;
              //transition: all 0.3s;
              position: absolute;
              top: 0;
              left: 0;
            }
            @media screen and (max-width:400px){
              padding-top:40% ;
            }
          }


          //.fade-image {
          //  z-index: 1;
          //  opacity: 1;
          //}
          //
          //.main-image {
          //  z-index: 0;
          //  opacity: 0;
          //}

        }
      }
    }

    .partners-mobile-wrapper{
      display: none;
    }
    @media screen and (max-width: 1250px){
      width: 1020px;
    }
    @media all and (max-width: 1050px) {
      width: 100%;
      padding-right: 0;
      .partners-title {
        font-size: 28px;
        margin: 80px 0;
      }
      .next-btn, .prev-btn {
        display: none;
      }
      .partners-list-wrapper{
        display: none;
      }
      .partners-mobile-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        .partner-item-wrapper{
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          .partner-item {
            //width: 90%;
            //height: 100%;
            //position: relative;
            width: 100%;
            height: 100%;
            margin-right: 20px;

            .partner-content {
              width: 100%;
              height: 100%;
              padding-top: 28%;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                //height: 100%;
                //margin: auto;
                //transition: all 0.3s;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
              }
              @media screen and (max-width:400px){
                padding-top:34% ;
              }
            }


            //.fade-image {
            //  z-index: 1;
            //  opacity: 1;
            //}
            //
            //.main-image {
            //  z-index: 0;
            //  opacity: 0;
            //}

          }
        }
      }
    }
    @media all and (max-width: 700px) {
      .partners-title {
        font-size: 22px;
        margin: 60px 0;
      }
    }
    @media all and (max-width: 400px) {
      .partners-title {
        font-size: 22px;
        margin: 60px 0;
      }
    }
  }
}