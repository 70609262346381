@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";


.members-card-wrapper{
  width: calc(92% / 4);
  background-color: #F9FAFB;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;
  border-radius: 8px;
  margin-top: 20px;
  .img-block{
    width: 100%;
    .members-img-wrapper{
      padding-top: 100%;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }
  .empty-wrapper{
    width: 100%;
    .empty-img{
      padding-top: 100%;
      position: relative;
      background-color: #EAEAEA;
      border-radius: 50%;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }
  .members-name{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 20px;
    height: 60px;
  }
  .members-position{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color:$brandColor;
    height: 60px;
  }
  @media screen and (max-width: 1100px){
    width: 90%;
    margin-right: unset;
    margin-top: 40px;
  }
}