.ant-modal{
  width: 650px !important;
  height: 445px;
  transform-origin: unset !important;
  animation-duration: unset !important;
}
.ant-modal-content{
  border-radius: 15px;
  height: 100%;
  .ant-modal-close{
    top: 6px;
    right: 2px;
    .ant-modal-close-x{
      color: white;
      font-size: 22px;
    }
  }
  .ant-modal-header{
    height: 68px;
    display: flex;
    align-items: center;
    background-color:#2B5A60;
    border-radius: 15px 15px 0 0;
    .ant-modal-title{
      color: white;
      font-size: 17px;
    }
  }
  .ant-modal-body{
    display: flex;
    flex-direction: column;
    .first-block{
      width: 100%;
      display: flex;
     justify-content: space-between;
    }
    .second-block{
      width: 100%;
      display: flex;
     justify-content: space-between;
    }
    .checked-block{
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .custom-input-group {
      width: 45%;
      input {
        border-bottom: 1.2px solid #252629;
      }
    }
  }
  .ant-modal-footer{
    border: 0;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 0;
  }
  .ant-select-selector{
    height: 52px !important;
    width: 100% !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .valid{
    width: 45%;
    height: 53px;
    border-bottom: 1.2px solid #252629;
  }
  .err{
    width: 45%;
    height: 53px;
    border-bottom: 1.2px solid red;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector{
    border: 0;
    border-bottom: 2px solid #2B5A60 ;
  }
  .errBorder .ant-checkbox-inner{
    border: 1.2px solid red !important;
  }
  .error .ant-checkbox-inner{
    border: 1.2px solid red !important;
  }
  .checkboxWrapper{
   .ant-checkbox-wrapper{
     height: 112px;
     display: flex;
     align-items: center;
   }
  }
  .ant-checkbox-wrapper{
    display: flex;
    align-items: center;
  }
  .ant-checkbox{
    margin-bottom: 7px;
  }
  .ant-select-selection-item{
    padding-top:10px !important;
  }
  .ant-checkbox-inner{
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }
  .ant-checkbox-checked::after{
    border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after{
    border: 1.5px solid #252629;
    border-left: 0;
    border-top: 0;
  }
  .ant-picker{
    padding: 0 ;
    width: 100%;
    border: 0;
    input{
      border-radius: 0;
    }
    .ant-picker-suffix{
      position: absolute;
      right: 0;
    }
  }
  .ant-select-selection-placeholder{
    margin-top: 10px;
    color: #949BA4;
  }
  .ant-picker-focused{
    box-shadow: unset;
  }
  .ant-modal-footer{
    .ant-btn {
      &.ant-btn-default {
        display: none !important;
      }
      &.ant-btn-primary{
        position: absolute;
        bottom: 30px;
        right: 20px;
        width: 120px;
        height: 39px;
        border-radius: 25px;
        font-size: 16px;
        border-color: #2B5A60 !important;
        background: #2B5A60!important;
      }
    }
  }
  .ant-select-arrow{
    right: 0;
    svg{
      path{
        fill:#949BA4 !important; ;
      }
    }
  }
  .ant-checkbox + span{
    padding: 0 0 0 8px;
    height: 64px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

@media screen  and (max-width: 800px){
  .ant-modal{
    height: 580px;
  }
  .ant-modal-body{
    .first-block{
      display: flex;
      flex-direction: column;
    }
    .second-block{
      display: flex;
      flex-direction: column;
    }
    .checked-block{
      margin-top: 30px;
    }
    .custom-input-group {
      width: 100% !important;
    }
  }
  .ant-modal-content .ant-picker{
    margin-left: 24%;
    width: 76% !important;
  }
  .valid{
    width: 100% !important;
  }
  .err{
    width: 100% !important;
  }
  .checkboxWrapper{
    .ant-checkbox-wrapper{
      margin-left: 20%;
    }
  }
}