@import "../mainVariables";
@import "../mixins";

.contacts-wrapper {
  width: 100%;
  position: relative;
  background-color:#1D2939;
  height: 230px;

  .form-content{
    width: 1120px;
    height: 240px;
    background-color: #608CEC;
    border-radius: 16px;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 64px;
    .contacts-info-block{
      width: 54%;
      display: flex;
      flex-direction: column;
      .title{
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
        margin-bottom: 10px;
      }
      .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: white;
      }
    }
    .input-wrapper{
      width: 44%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .sent-email{
        padding: 12px 20px;
        background-color:$brandColor;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-left: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .custom-input-group{
        width: 70%;
        margin-bottom: unset;
        input{
          padding: 12px 14px;
          border-radius: 8px;
          background-color: white;
          border-bottom: unset;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .form-content{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px){
    .form-content{
      width: 90%;
      flex-direction: column;
      padding: 32px 24px;
      height: unset;
      top: -40px;
      .contacts-info-block{
        width: 100%;
      }
      .input-wrapper{
        width: 100%;
        flex-direction: column;
        padding-top: 32px;
        .custom-input-group{
          width: 100%;
        }
        .sent-email{
          width: 100%;
          margin-top: 16px;
          height: 48px;
          margin-left: unset;
        }
      }
    }
  }
}
