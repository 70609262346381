@import "../mainVariables";
@import "../mixins";


.mainBlock{
  width: 100%;
  height: 880px;
  overflow: hidden;
  background-color: #F9FAFB;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bgBlock{
    height: 80%;
    position: absolute;
    right: 0;
  }
  .responsiveBg-block{
    display: none;
  }
  .info-block{
    width: 1120px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text{
      width: 50%;
      .title{
        font-size: 50px;
        font-weight: 600;
        line-height: 68px;
        letter-spacing: -0.02em;
        text-align: left
      }
      .description{
        width: 80%;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        margin-top: 24px;
      }
    }
    .contact-block{
      width: 48%;
      display: flex;
      justify-content: flex-end;
      margin-right: 90px;

      .messenger{
        width: 480px;
        height: 650px;
        background-color: white;
        position: relative;
        border-radius: 40px;
        box-shadow: 20px 20px 2px 1px  #FFFFFF80;
        .girl{
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
        .viber{
          position: absolute;
          top: 160px;
          left: 80px;
          cursor: pointer;
        }
        .telegram{
          position: absolute;
          top: 270px;
          left: 53px;
          cursor: pointer;
        }
        .whatsApp{
          cursor: pointer;
          position: absolute;
          bottom: 200px;
          left: 116px;
        }
        .message{
          position: absolute;
          top: 74%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
        .call-block{
          width: 170px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 88%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
        .chat{
          position: absolute;
          top: 270px;
          right: -20px;
          cursor: pointer;
        }
      }
    }
    .consultation{
      display:none;
    }
  }

  @media screen and (max-width: 1250px){
    .info-block{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px){
    height: 560px;
    .bgBlock{
      display: none;
    }
    .responsiveBg-block{
      display: flex;
      position: absolute;
      right: 0;
    }

    .info-block{
      width: 100%;
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
      padding: 60px $mainPadding 0 $mainPadding;
      .text{
        width: 80%;
      }
      .contact-block{
        display: none;
      }
      .consultation{
        cursor: pointer;
        display:flex;
        background-color:$brandColor;
        width: 40%;
        align-self: center;
        justify-content: center;
        padding: 12px 0;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        color: white;
        margin-top: 44px;
      }
    }
  }

  @media screen and (max-width: 700px){
    .info-block{
      .text{
        width: 100%;
        .title{
          font-size: 36px;
          font-weight: 600;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .description{
          width: 100%;
          font-size: 18px;
        }
      }
      .consultation{
        width: 96%;
      }
    }
  }
}